<template>
  <div>
    <v-card
      class="mb-6"
      v-if="approveChannel.length"
    >
      <!-- <v-icon>mdi-folder-play</v-icon>
        <v-icon>mdi-file-video-outline</v-icon>
        <v-icon>mdi-note-plus</v-icon>
        <v-icon>mdi-note-edit-outline</v-icon> -->
      <v-card-text>
        <div class="d-flex align-center mb-4">
          <v-icon
            class="mr-2"
            color="#ff0101"
            x-large
          >mdi-file-video-outline</v-icon>
          <h5>Campaign Post</h5>

          <v-row class="d-flex justify-end mr-1">
            <v-btn
              depressed
              :disabled="!approveChannel.length"
              dark
              small
              color="primary"
              @click="openDialogue()"
            >Add Post</v-btn>
            <v-icon
              large
              class="refresh"
              color="primary"
              @click="refresh()"
            >mdi-refresh-circle</v-icon>
          </v-row>
        </div>

        <v-progress-linear
          indeterminate
          color="primary"
          class="mb-8"
          v-if="loading"
        ></v-progress-linear>

        <div v-if="campaignPostData.length > 0">
          <div
            v-for="post in campaignPostData"
            :key="post.post_id"
            class="post"
          >
            <div class="d-flex justify-space-between">
              <p class="channel-name d-flex align">
                <span>
                  <a
                    :href="post.channel_link"
                    target="_blank"
                    class=""
                  >
                    <strong>{{ post.channel_name }}</strong></a><br />
                  <v-chip
                    color="teal"
                    class="mt-1"
                    text-color="white"
                    x-small
                  >{{post.category}}</v-chip>
                  <!-- <a
                    :href="post.channel_link"
                    target="_blank"
                    class="linkChannel"
                  ><small class="linkcolor">{{ post.channel_id }}</small></a> -->
                </span>
              </p>
              <div>
                <v-btn
                  v-if="post.admin_status!==5"
                  outlined
                  rounded
                  x-small
                  dark
                  color="#F54153"
                  @click="editDialogue(post)"
                >
                  <v-icon
                    x-small
                    class="mr-1"
                  >mdi-pencil</v-icon>
                  Edit Post
                </v-btn>
                <v-btn
                  outlined
                  rounded
                  class="ml-1"
                  x-small
                  color="info"
                  :href="post.post_link"
                  target="_blank"
                >
                  <v-icon
                    small
                    class="mr-1"
                  >mdi-link</v-icon>
                  View Post
                </v-btn>

              </div>
            </div>

            <div class="d-flex justify-space-between">
              <p>
                <span class="label">Status</span> <br>
                <span
                  class="red--text"
                  v-if="post.admin_status === 2"
                >
                  <v-icon
                    class="red--text"
                    small
                  >mdi-close-outline</v-icon>
                  Rejected
                </span>
                <span
                  class="warning--text"
                  v-if="post.admin_status === 3"
                >
                  <v-icon
                    class="warning--text"
                    small
                  >mdi-eye-settings-outline</v-icon>
                  Under Review
                </span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                      class="red--text"
                      v-if="post.admin_status === 4"
                    >
                      <v-icon
                        class="red--text"
                        small
                      >mdi-content-duplicate</v-icon>
                      Re-Submit
                    </span>
                    <!-- <span v-bind="attrs" v-on="on">This text has a tooltip</span> -->
                  </template>
                  <span>Re-Submit the post by makeing updates mentioned in remarks</span>
                </v-tooltip>
                <span
                  class="success--text"
                  v-if="post.admin_status === 5"
                >
                  <v-icon
                    class="success--text"
                    small
                  >mdi-file-document-check-outline</v-icon>
                  Approved
                </span>
              </p>

              <p>
                <span class="label">Views</span> <br>
                <strong>{{ post.view_count | formatNumber }}</strong>
              </p>

              <p>
                <span class="label">Likes</span> <br>
                <strong>{{ post.like_count | formatNumber }}</strong>
              </p>

              <p>
                <span class="label">Eng. Rate</span> <br>
                <strong>{{ post.post_eng_rate || 0 }}%</strong>
              </p>

              <p>
                <span class="label">Comments</span> <br>
                <strong>{{ post.comment_count | formatNumber }}</strong>
              </p>

              <p>
                <span class="label">Date</span> <br>
                <strong>{{ post.add_time | dateFormatMonth }}</strong>
              </p>

              <p></p>
            </div>
            <p v-if="post.admin_status===4">
              <span class="label">Remark</span> <br />
              {{ post.remark || 'NA'}}
            </p>
          </div>
        </div>

        <div
          class="text-center ma-8"
          v-if="!loading && campaignPostData.length <= 0"
        >
          <img
            src="@/assets/images/no-campaign.png"
            width="150"
          />
          <h3>No Post Found</h3>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="postDialogue"
      width="35%"
      persistent
    >
      <v-card class="pa-4">
        <h4 class="mt-2 mb-2">Add Campaign Post</h4>
        <v-divider class="mt-4 mb-6"></v-divider>
        <v-form
          v-model="valid"
          lazy-validation
          ref="campaignForm"
          class=""
        >
          <v-row>
            <v-col cols="12">
              <label class="label">Approved channel</label>
              <v-select
                :items="approveChannel"
                :rules="campaignRules.channeldata"
                v-model="campaign.channeldata"
                label="Select Channel"
                item-text="name"
                item-value="channeldata"
                solo
              ></v-select>
            </v-col>
            <!-- <v-col cols="12">
              <label class="label">Campaign Type</label>
              <v-select
                :items="campaignTypeList"
                v-model="campaign.campaignType"
                label="Select Content Type"
                item-text="label"
                item-value="value"
                disabled
                solo
              ></v-select>
            </v-col>
            <v-col cols="12">
              <label class="label">Content Type</label>
              <v-select
                :items="campaignData.promo_platform === 1 ? contentYoutube : contentInstagram"
                v-model="campaign.contentType"
                label="Select Campaign Type"
                item-text="label"
                item-value="value"
                disabled
                solo
              ></v-select>
            </v-col> -->
            <v-col cols="12">
              <label class="label">Post URL</label>
              <v-text-field
                v-if="this.campaignData.content_type === 1 || this.campaignData.content_type === 2"
                v-model="campaign.postUrl"
                :rules="campaignRules.postUrl1"
                placeholder="https://www.youtube.com/watch?v=VIDEO ID"
                solo
              ></v-text-field>
              <v-text-field
                v-if="this.campaignData.content_type === 3"
                v-model="campaign.postUrl"
                :rules="campaignRules.postUrl2"
                placeholder="https://www.youtube.com/shorts/SHORT ID"
                solo
              ></v-text-field>
              <v-text-field
                v-if="this.campaignData.content_type === 6 || this.campaignData.content_type === 7"
                v-model="campaign.postUrl"
                :rules="campaignRules.postUrl3"
                placeholder="https://www.instagram.com/p/POST ID/"
                solo
              ></v-text-field>
              <v-text-field
                v-if="this.campaignData.content_type === 4 || this.campaignData.content_type === 5"
                v-model="campaign.postUrl"
                :rules="campaignRules.postUrl4"
                placeholder="https://www.instagram.com/stories/USERNAME/STORY ID/"
                solo
              ></v-text-field>
              <v-text-field
                v-if="this.campaignData.content_type === 8"
                v-model="campaign.postUrl"
                :rules="campaignRules.postUrl5"
                placeholder="https://www.instagram.com/reel/REEL ID/"
                solo
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              v-if="this.campaignData.promo_platform===2"
            >
              <label class="label">Post Type</label>
              <v-select
                :items="postList"
                label="Select Post Type"
                v-model="campaign.isVideo"
                :rules="campaignRules.isVideo"
                item-text="label"
                item-value="value"
                solo
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              class="text-right mb-4"
            >
              <v-btn
                depressed
                dark
                large
                color="primary"
                class="mr-4"
                @click="close()"
                :disabled="loadbtn"
              >Close</v-btn>
              <v-btn
                depressed
                dark
                large
                color="primary"
                @click="submitPost()"
                :loading="loadbtn"
              >Submit</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="editPostDialogue"
      width="35%"
      persistent
    >
      <v-card class="pa-4">
        <h4 class="mt-2 mb-2">Edit Campaign Post</h4>
        <v-divider class="mt-4 mb-6"></v-divider>
        <v-form
          v-model="valid"
          lazy-validation
          ref="campaignForm"
          class=""
        >
          <v-row>
            <v-col cols="12">
              <label class="label">Approved channel</label>
              <v-select
                :items="approveChannel"
                :rules="campaignRules.channeldata"
                v-model="editCampaign.channeldata"
                label="Select Channel"
                item-text="name"
                item-value="channeldata"
                solo
              ></v-select>
            </v-col>
            <v-col cols="12">
              <label class="label">Post URL</label>
              <v-text-field
                v-if="this.campaignData.content_type === 1 || this.campaignData.content_type === 2"
                v-model="editCampaign.postUrl"
                :rules="campaignRules.postUrl1"
                placeholder="https://www.youtube.com/watch?v=VIDEO ID"
                solo
              ></v-text-field>
              <v-text-field
                v-if="this.campaignData.content_type === 3"
                v-model="editCampaign.postUrl"
                :rules="campaignRules.postUrl2"
                placeholder="https://www.youtube.com/shorts/SHORT ID"
                solo
              ></v-text-field>
              <v-text-field
                v-if="this.campaignData.content_type === 6 || this.campaignData.content_type === 7"
                v-model="editCampaign.postUrl"
                :rules="campaignRules.postUrl3"
                placeholder="https://www.instagram.com/p/POST ID/"
                solo
              ></v-text-field>
              <v-text-field
                v-if="this.campaignData.content_type === 4 || this.campaignData.content_type === 5"
                v-model="editCampaign.postUrl"
                :rules="campaignRules.postUrl4"
                placeholder="https://www.instagram.com/stories/USERNAME/STORY ID/"
                solo
              ></v-text-field>
              <v-text-field
                v-if="this.campaignData.content_type === 8"
                v-model="editCampaign.postUrl"
                :rules="campaignRules.postUrl5"
                placeholder="https://www.instagram.com/reel/REEL ID/"
                solo
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              v-if="this.campaignData.promo_platform===2"
            >
              <label class="label">Post Type</label>
              <v-select
                :items="postList"
                label="Select Post Type"
                v-model="editCampaign.isVideo"
                :rules="campaignRules.isVideo"
                item-text="label"
                item-value="value"
                solo
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              class="text-right mb-4"
            >
              <v-btn
                depressed
                dark
                large
                color="primary"
                class="mr-4"
                @click="close()"
                :disabled="loadbtn"
              >Close</v-btn>
              <v-btn
                depressed
                dark
                large
                color="primary"
                @click="editPost()"
                :loading="loadbtn"
              >Submit</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { campaignPost, addPost, updatePost } from '@/api/campaign'
// import constantData from '@/utils/constant'
export default {
  props: {
    approveChannel: {
      type: Array,
      default: () => null
    },
    campaignData: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      valid: true,
      loadbtn: false,
      editPostDialogue: false,
      campaign: {},
      editCampaign: {},
      campaignPostData: [],
      postDialogue: false,
      loading: false,
      params: {
        campaignId: this.$route.params.campaignId,
        platform: this.campaignData.promo_platform
      },
      postList: [{
        value: '0',
        label: 'Image'
      }, {
        value: '1',
        label: 'Video'
      }],
      campaignRules: {
        channeldata: [
          v => !!v || 'Please select channel'
        ],
        isVideo: [
          v => !!v || 'Please select post type'
        ],
        postUrl1: [
          v => !!v || 'Post Url is required',
          v => /^https:\/\/www.youtube.com\/watch\?v=[A-Za-z0-9_&=]/gi.test(v) || 'Invalid Post Url ! use this format (https://www.youtube.com/watch?v=VIDEO ID)'
        ],
        postUrl2: [
          v => !!v || 'Post Url is required',
          v => /^https:\/\/www.youtube.com\/shorts\/[A-Za-z0-9_-]/gi.test(v) || 'Invalid Post Url. Use this format (https://www.youtube.com/shorts/SHORT ID)'
        ],
        postUrl3: [
          v => !!v || 'Post Url is required',
          v => /(https?:\/\/(?:www\.)?instagram\.com\/p\/([^/?#&]+)\/+$).*/gi.test(v) || 'Invalid Post Url. Use this format (https://www.instagram.com/p/POST ID/)'
        ],
        postUrl4: [
          v => !!v || 'Post Url is required',
          v => /(https?:\/\/(?:www\.)?instagram\.com\/stories\/.*\/([^/?#&]+)\/+$).*/gi.test(v) || 'Invalid Post Url. Use this format (https://www.instagram.com/stories/USERNAME/STORY ID/)'
        ],
        postUrl5: [
          v => !!v || 'Post Url is required',
          v => /(https?:\/\/(?:www\.)?instagram\.com\/reel\/([^/?#&]+)\/+$).*/gi.test(v) || 'Invalid Post Url. Use this format (https://www.instagram.com/reel/REEL ID/)'
        ]
      }
      // contentYoutube: constantData.contentYoutube,
      // contentInstagram: constantData.contentInstagram,
      // campaignTypeList: constantData.campaignTypeList
    }
  },
  mounted () {
    this.getpostList()
  },
  methods: {
    refresh () {
      this.loading = true
      this.getpostList()
    },
    async getpostList () {
      this.campaignPostData = []
      const data = await campaignPost(this.params)
      this.loading = false
      if (data.success) {
        this.campaignPostData = data.campaigns
      } else {
        this.$snackbar({
          message: data.message
        })
      }
    },
    editDialogue (row) {
      this.editCampaign.channeldata = {
        media_id: row.media_id,
        channel_id: row.channel_id
      }
      this.editCampaign.postUrl = row.post_link
      this.editCampaign.icid = row.icid
      this.editCampaign.isVideo = this.campaignData.promo_platform === 2 ? row.is_video.toString() : null
      this.editPostDialogue = true
    },
    resetPost () {
      this.campaign.channeldata = null
      this.campaign.isVideo = null
      this.campaign.postUrl = null
      this.editCampaign.channeldata = null
      this.editCampaign.isVideo = null
      this.editCampaign.postUrl = null
    },
    openDialogue () {
      this.resetPost()
      this.postDialogue = true
    },
    close () {
      this.postDialogue = false
      this.editPostDialogue = false
      this.resetPost()
    },
    changeChannel () {
    },
    async submitPost () {
      try {
        if (this.$refs.campaignForm.validate()) {
          this.loadbtn = true
          const postData = {
            campaignId: this.$route.params.campaignId,
            media_id: this.campaign.channeldata.media_id,
            channel_id: this.campaign.channeldata.channel_id,
            media_type: this.campaignData.promo_platform,
            is_video: this.campaign.isVideo,
            post_link: this.campaign.postUrl,
            campaign_type: this.campaignData.campaign_type,
            content_type: this.campaignData.content_type,
            client_id: this.campaignData.client_id
          }
          const data = await addPost(postData)
          this.loadbtn = false
          if (data.success) {
            this.$snackbar({
              message: data.message
            })
            this.postDialogue = false
            this.loading = true
            this.getpostList()
          } else {
            this.$snackbar({
              message: data.message,
              color: 'error',
              timeout: 5000
            })
          }
        } else {
          console.log('error submit!!')
        }
      } catch (e) {
        console.log('error', e)
      }
    },
    async editPost () {
      try {
        if (this.$refs.campaignForm.validate()) {
          this.loadbtn = true
          const postData = {
            campaignId: this.$route.params.campaignId,
            media_id: this.editCampaign.channeldata.media_id,
            channel_id: this.editCampaign.channeldata.channel_id,
            media_type: this.campaignData.promo_platform,
            is_video: this.editCampaign.isVideo,
            post_link: this.editCampaign.postUrl,
            campaign_type: this.campaignData.campaign_type,
            content_type: this.campaignData.content_type,
            id: this.editCampaign.icid,
            client_id: this.campaignData.client_id
          }
          const data = await updatePost(postData)
          this.loadbtn = false
          if (data.success) {
            this.$snackbar({
              message: data.message
            })
            this.editPostDialogue = false
            this.getpostList()
          }
          // } else {
          //   this.$snackbar({
          //     message: data.message,
          //     color: 'error',
          //     timeout: 5000
          //   })
          // }
        } else {
          console.log('error submit!!')
        }
      } catch (e) {
        console.log('error', e)
      }
    }
  }
}
</script>

<style scoped>
  h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #6b6b6b;
  }
  h4 {
    color: #333333;
    font-weight: 600;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    color: #333333;
  }
  strong {
    font-weight: 600;
  }
  .v-data-table >>> .v-data-table__wrapper > table {
    border-spacing: 0 12px !important;
  }
  .theme--light.v-data-table >>> .v-data-table__wrapper table th {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #333333;
    border: none !important;
    padding: 5px 6px;
  }
  .v-data-table >>> .v-data-table__wrapper > table > tbody > tr > td {
    font-weight: normal;
    font-size: 14px;
    line-height: normal;
    color: #333333;
    border: none !important;
    padding: 5px 6px;
  }
  .v-data-table >>> .v-data-table__wrapper > table > tbody > tr {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.09);
    border-radius: 13px;
  }
  .v-data-table >>> tbody tr.v-data-table__selected {
    background: #e9ecfa;
  }
  .v-input >>> .v-input--selection-controls__input {
    margin: 0 !important;
  }
  .v-text-field.v-text-field--enclosed.v-text-field--solo.v-select {
    margin: 5px 0 0;
  }
  .v-input >>> .theme--light.v-icon {
    font-size: 24px !important;
    color: #f54153 !important;
  }
  .refresh {
    cursor: pointer;
    margin-left: 20px;
  }
  .v-image {
    border-radius: 50%;
    height: 45px;
    width: 45px;
  }
  /* posts list */
  .channel-name a strong:hover {
    color: #f54153 !important;
  }
  .channel-name a strong {
    color: #333333 !important;
  }
  .post {
    padding: 10px 15px 5px;
    margin: 0 0 15px;
    border: 1px solid #ccc;
    border-radius: 11px;
  }
  .post p {
    margin: 0 0 12px !important;
    line-height: 18px;
  }
  .post p.channel-name {
    font-size: 16px;
    line-height: 18px;
  }
</style>
