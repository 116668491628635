import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../layout/Layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
    component: Layout,
    children: [{
      path: 'dashboard',
      name: 'dashboard',
      component: () => import('@/views/dashboard/Index.vue')
    }]
  },
  {
    path: '/campaign',
    name: 'campaign',
    component: Layout,
    children: [
      {
        path: 'list',
        name: 'campaignlist',
        component: () => import('@/views/campaign/Index.vue')
      },
      {
        path: 'details/:campaignId/:status',
        name: 'campaignDetails',
        component: () => import('@/views/campaign/Details.vue')
      }
    ]
  },
  {
    path: '/profile',
    component: Layout,
    children: [
      {
        path: 'info',
        name: 'profile',
        component: () => import('@/views/profile/Index.vue')
      }
    ]
  },
  {
    path: '/user/login',
    name: 'login',
    component: () => import('@/views/user/Login.vue')
  },
  {
    path: '/user/forgot-password',
    name: 'forgot',
    component: () => import('@/views/user/Forgot.vue')
  },
  {
    path: '/user/register',
    name: 'register',
    component: () => import('@/views/user/Register.vue')
  },
  {
    path: '/user/add-info',
    name: 'addinfo',
    component: () => import('@/views/user/AddInfo.vue')
  },
  {
    path: '/user/reset-password',
    name: 'resetPassword',
    component: () => import('@/views/user/ResetPassword.vue')
  },
  {
    path: '/auth/instagram',
    name: 'instagramAuth',
    component: () => import('@/views/auth/instagram.vue')
  },
  {
    path: '/user/connect',
    name: 'connect',
    component: () => import('@/views/user/Connect.vue')
  },

  // public pages
  {
    path: '/public-campaign',
    name: 'publicCampaign',
    component: Layout,
    children: [
      {
        path: 'list',
        name: 'publicCampaignList',
        component: () => import('@/views/campaign/Index.vue')
      },
      {
        path: 'details/:campaignId/:applyType',
        name: 'publicCampaignDetails',
        component: () => import('@/views/campaign/Details.vue')
      }
    ]
  },
  {
    path: '/legal',
    name: 'legal',
    component: Layout,
    children: [
      {
        path: 'privacy-policy',
        name: 'privacy',
        component: () => import('@/views/static/Privacy.vue')
      },
      {
        path: 'terms',
        name: 'terms',
        component: () => import('@/views/static/Terms.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  }
})

router.beforeEach((to, from, next) => {
  const publicPages = ['login', 'forgot', 'register', 'resetPassword', 'privacy', 'terms', 'campaignlist', 'campaignDetails']
  const isPublicRoute = publicPages.includes(to.name)
  const loggedIn = window.localStorage.getItem('user-token')

  if (isPublicRoute) {
    if (!loggedIn || (to.name === 'campaignlist' || to.name === 'campaignDetails')) {
      next()
    } else {
      next('/dashboard')
    }
  } else {
    const userInfo = JSON.parse(window.localStorage.getItem('user'))
    if (loggedIn) {
      if ((!userInfo.status || !userInfo.status === 2) && to.name !== 'connect') {
        next('/user/connect')
        // next('/user/add-info')
      // } else if ((!userInfo.name || userInfo.name === '') && to.name !== 'addinfo') {
      //   next('/user/add-info?step=2')
      } else {
        next()
      }
    } else {
      next(`/user/login?redirect=${from.path}`)
    }
  }
})

export default router
