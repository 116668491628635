<template>
  <div class="campaign">
    <h4
      class="mb-4"
      v-if="loggedIn"
    >Live Campaigns</h4>

    <v-progress-linear
      class="my-4"
      indeterminate
      color="primary"
      v-if="loading"
    ></v-progress-linear>

    <div
      class="mb-4 d-flex justify-space-between"
      v-for="item in campaignData"
      :key="item.campaign_id"
    >
      <v-card
        class="mr-2"
        style="width: 100%;"
      >
        <v-card-text class="d-flex justify-space-between align-center">
          <v-img
            :src="item.avatar"
            lazy-src="@/assets/images/placeholder.png"
            :aspect-ratio="16/16"
            width="150"
            class="cover mr-2"
            v-if="item.avatar && item.avatar != null"
          />
          <v-img
            :src="`https://ui-avatars.com/api/?background=F54153&color=fff&name=${item.campaign_name ? item.campaign_name : 'NA'}`"
            :aspect-ratio="16/16"
            width="150"
            class="cover mr-2"
            v-else
          />

          <div style="width: 100%;">
            <div class="d-flex justify-space-between text-center">
              <p>
                <span class="label">Campaign Name</span> <br />
                <strong>{{item.campaign_name}}</strong>
              </p>
              <p>
                <span class="label">Platform</span> <br />
                <strong>{{ item.promo_platform | platform}}</strong>
              </p>
              <p>
                <span class="label">Campaign Type</span> <br />
                <strong>{{ item.campaign_type | campaignType }}</strong>
              </p>
              <p>
                <span class="label">Content Type</span> <br>
                <strong v-if="item.promo_platform === 1">{{ item.content_type | youtube  }}</strong>
                <strong v-else>{{ item.content_type | instagram  }}</strong>
              </p>
              <p>
                <span class="label">Application Timeline</span> <br />
                <strong>{{ item.start_date | dateFormatMonth }} ~ {{ item.end_date | dateFormatMonth }}</strong>
              </p>
            </div>

            <v-divider class="my-3"></v-divider>

            <div class="d-flex justify-space-between text-center">
              <p>
                <span class="label">Category</span> <br>
                <strong>
                  <span
                    v-for="category in item.category"
                    :key="category"
                  >{{ category }}, </span>
                </strong>
              </p>
              <p>
                <span class="label">Language</span> <br>
                <strong>
                  <span
                    v-for="language in item.language"
                    :key="language"
                  >{{ language }}, </span>
                </strong>
              </p>
              <p>
                <span class="label">Gender</span> <br>
                <strong>
                  <span
                    v-for="gender in item.gender"
                    :key="gender"
                  >{{ gender }}, </span>
                </strong>
              </p>
              <p>
                <span class="label">Budget</span> <br />
                <strong>{{ item.budget | formatNumber }}</strong>
              </p>
              <p>
                <span class="label">{{item.promo_platform === 1 ? 'Minimum Subscribers' : 'Minimum Followers'}}</span> <br>
                <strong>{{ item.min_follower | formatNumber  }} ~ {{ item.max_follower | formatNumber }}</strong>
              </p>
            </div>
          </div>
        </v-card-text>
      </v-card>

      <div
        class="d-flex flex-column"
        style="width: 85px;"
      >
        <!-- :outlined="item.is_apply === 1 ? false : true" -->
        <!-- :disabled="(item.status) ? true : false" -->
        <v-btn
          width="85"
          class="mb-4"
          color="primary"
          block
          :outlined="(item.status===null)? true : false"
          depressed
          @click="applyremove(item)"
        >
          <!-- {{ item.is_apply ? (item.status && item.status !== 0 ? `${status(item.status)}` : 'Applied') : 'Apply Now' }} -->
          {{item.status === null ? 'Apply Now' : 'Applied'}}
        </v-btn>
        <v-btn
          width="85"
          color="primary"
          block
          outlined
          depressed
          :to="`/campaign/details/${item.campaign_id}/${item.status===null ? 4 : item.status}`"
        >View More</v-btn>
      </div>
    </div>

    <v-pagination
      v-model="params.page"
      :length="this.totalPages"
      @input="getList()"
      :total-visible="10"
      v-if="!loading && this.totalPages > 1"
    ></v-pagination>

    <div
      class="text-center ma-8"
      v-if="!loading && campaignData.length <= 0"
    >
      <img
        src="@/assets/images/no-campaign.png"
        width="150"
      />
      <h3>No Live Campaigns Found</h3>
    </div>

    <v-dialog
      v-model="channelDialogue"
      width="50%"
      persistent
    >
      <v-card class="pa-4">
        <div v-if="channelIds.length > 0">
          <p class="mb-4">
            <strong>Please select the channel which you want to apply</strong>
          </p>
          <v-progress-linear
            v-if="loadingDialogue"
            class="mb-8"
            indeterminate
            color="primary"
          >
          </v-progress-linear>
          <v-row
            v-else
            class="d-flex align-center"
          >
            <!-- {{mediaId}} -->
            <v-col
              v-for="item in channelIds"
              :key="item.id"
              cols="12"
              md="6"
              xm="12"
              style="padding:6px;"
            >
              <div class="d-inline-flex align-center channelCard">
                <v-checkbox
                  v-model="mediaId"
                  :value="{media_id: item.id, status: item.status}"
                  multiple
                  hide-details
                  :indeterminate="item.status===2?true:false"
                  :disabled="item.status===1 ||item.status===2"
                  class="ma-0 mr-3"
                ></v-checkbox>
                <div class="d-flex align-center">
                  <img
                    v-if="item.media_type === 1"
                    class="mr-2"
                    src="@/assets/images/icon-instagram.svg"
                    width="30"
                  />
                  <v-icon
                    v-else
                    class="mr-2"
                    color="#ff0101"
                    x-large
                  >mdi-youtube</v-icon>
                  <p>
                    <strong>{{ item.name }}</strong>
                    <v-tooltip
                      top
                      color="primary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="success"
                          v-if="item.verify"
                          v-bind="attrs"
                          v-on="on"
                          small
                          class="ml-1"
                        >mdi-check-decagram</v-icon>
                        <v-icon
                          color="warning"
                          v-bind="attrs"
                          v-on="on"
                          small
                          v-else
                          class="ml-1"
                        >mdi-alert-circle</v-icon>
                      </template>
                      <v-chip
                        v-if="item.status !== null"
                        small
                        color="white"
                        outlined
                      >
                        <v-icon
                          small
                          v-if="item.status===0"
                          left
                        >mdi-alert-circle-outline</v-icon>
                        <v-icon
                          small
                          v-if="item.status===1"
                          left
                        >mdi-check-circle-outline</v-icon>
                        <v-icon
                          small
                          v-if="item.status===2"
                          left
                        >mdi-close-octagon-outline</v-icon>{{item.status | influencerStatus}}
                      </v-chip>
                      <div class="d-flex justify-center">
                        <span v-if="item.verify">Authorized Channel</span>
                        <span v-else>{{item.media_type === 0 ? 'This channel is not authorized. please go to profile page and connect your YouTube account' : 'This channel is not authorized. please go to profile page and connect your Instagram page'}}</span>
                      </div>
                    </v-tooltip>
                    <br />
                    <a
                      :href="`${item.media_type === 0 ? 'https://www.youtube.com/channel/'+ item.channel_id:'https://www.instagram.com/'+item.insta_username}`"
                      target="_blank"
                      class="linkChannel"
                    >
                      <small>{{ item.media_type === 0 ? item.channel_id : item.insta_username }}</small>
                    </a>
                  </p>
                </div>
              </div>
            </v-col>
          </v-row>
          <!-- <div
            v-else
            class="channelCard mr-2 d-inline-flex align-center"
            v-for="item in channelIds"
            :key="item.channel_id"
          >
            <v-checkbox
              v-model="mediaId"
              :value="item.id"
              multiple
              class="ma-0 mr-3"
              hide-details
            ></v-checkbox>
            <div class="d-flex align-center">
              <img
                v-if="item.media_type === 1"
                class="mr-2"
                src="@/assets/images/icon-instagram.svg"
                width="30"
              />
              <v-icon
                v-else
                class="mr-2"
                color="#ff0101"
                x-large
              >mdi-youtube</v-icon>
              <p>
                <strong>{{ item.name }}</strong>
                <v-icon
                  color="success"
                  v-if="item.verify"
                  small
                  class="ml-1"
                >mdi-check-decagram</v-icon>
                <br />
                <small>{{ item.media_type === 0 ? item.channel_id : item.insta_username }}</small>
              </p>
            </div>
          </div> -->

          <div class="d-flex justify-end mt-6">
            <v-btn
              color="secondary"
              class="mr-4"
              outlined
              small
              :disabled="loadingbtn"
              @click="channelDialogue=false"
            >
              Close
            </v-btn>

            <v-btn
              color="primary"
              outlined
              small
              :disabled="loadingDialogue"
              @click="submitdialog()"
              :loading="loadingbtn"
            >
              Submit
            </v-btn>
          </div>
        </div>

        <div v-else>
          <p class="text-center mt-4">
            <strong class="text-uppercase">You must link your social media accounts in order to apply for a campaign.</strong>
            <br> <br>
            Please go to your profile and connect your YouTube channel or enter the channel url under the <em>"Channel Links"</em> section.
            <br />
            <v-btn
              class="mt-4"
              color="primary"
              to="/profile/info"
              small
            >Go to profile</v-btn>
          </p>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getList, applyCampaign, applyChannel } from '@/api/campaign'
export default {
  data () {
    return {
      loggedIn: window.localStorage.getItem('user-token'),
      // influencerstatus: [{
      //   value: 0,
      //   label: 'Applied'
      // }, {
      //   value: 1,
      //   label: 'Approved'
      // }, {
      //   value: 2,
      //   label: 'Rejected'
      // }],
      params: {
        page: 1,
        size: 10
      },
      totalPages: 0,
      loading: false,
      loadingbtn: false,
      campaignData: [],
      channelDialogue: false,
      loadingDialogue: false,
      campData: {},
      channelIds: [],
      userData: [],
      mediaId: []
    }
  },
  mounted () {
    this.userData = JSON.parse(window.localStorage.getItem('user'))
    this.getList()
  },
  methods: {
    // status (dataValue) {
    //   const filtered = this.influencerstatus.filter(row => row.value === dataValue)
    //   if (filtered.length) {
    //     return filtered[0].label
    //   } else {
    //     return 'Apply Now'
    //   }
    // },
    async applyremove (item) {
      if (!this.loggedIn) {
        this.$router.push(`/user/login?redirect=${this.$route.path}`)
        return
      }
      this.mediaId = []
      this.campData = { ...item }
      // if (item.status === 0) {
      //   const data = await applyCampaign(this.campData)
      //   if (data.success) {
      //     this.$snackbar({
      //       message: data.message
      //     })
      //     const index = this.campaignData.findIndex(object => {
      //       return object.id === item.id
      //     })
      //     if (index !== -1) {
      //       this.campaignData[index].status = null
      //     }
      //     // this.getList()
      //   } else {
      //     this.$snackbar({
      //       message: data.message,
      //       color: 'error',
      //       timeout: 5000
      //     })
      //   }
      // } else {
      if (item.promo_platform === 1) {
        this.channelIds = this.userData.ytChannel
      } else {
        this.channelIds = this.userData.instaChannel
      }
      this.channelDialogue = true
      this.loadingDialogue = true
      const data = await applyChannel(item.campaign_id)
      if (data.success && this.channelIds.length) {
        for (let i = 0; i < this.channelIds.length; i++) {
          // use to match id from one arr obj to another arr obj and insert data into matched object.
          const index = data.appliedChannelData.findIndex(object =>
            object.media_id === this.channelIds[i].id
          )
          if (index !== -1) {
            this.channelIds[i].status = data.appliedChannelData[index].status
          } else {
            this.channelIds[i].status = null
          }
        }

        const filteredStatus = this.channelIds.filter(x => x.status != null)

        this.mediaId = filteredStatus.map(x => {
          if (x.status != null) {
            return {
              media_id: x.id, status: x.status
            }
          }
        })

        // this.mediaId = data.appliedChannelData
      }
      this.loadingDialogue = false
      // }
    },
    async submitdialog () {
      if (!this.loggedIn) {
        this.$router.push(`/user/login?redirect=${this.$route.path}`)
        return
      }
      // if (!this.mediaId.length) {
      //   this.$snackbar({
      //     message: 'Please select channel',
      //     color: 'error',
      //     timeout: 5000
      //   })
      //   return
      // }
      // this.campData.mediaId = this.mediaId
      this.loadingbtn = true
      const campInfluData = {
        campaignId: this.campData.campaign_id,
        mediaType: this.campData.promo_platform,
        mediaId: this.mediaId,
        client_id: this.campData.client_id
      }
      const data = await applyCampaign(campInfluData)
      if (data.success) {
        this.$snackbar({
          message: data.message
        })
        // this.getList()
        const index = this.campaignData.findIndex(object => {
          return object.id === this.campData.id
        })
        if (index !== -1 && this.mediaId.length) {
          this.campaignData[index].status = 0
        } else {
          this.campaignData[index].status = null
        }
      } else {
        this.$snackbar({
          message: data.message,
          color: 'error',
          timeout: 5000
        })
      }
      this.loadingbtn = false
      this.channelDialogue = false
    },
    async getList () {
      this.loading = true
      this.campaignData = []
      const data = await getList(this.params)
      if (data.success) {
        this.campaignData = data.campaigns.map(item => {
          item.category = item.category ? item.category.split(',') : []
          item.language = item.language ? item.language.split(',') : []
          item.gender = item.gender ? item.gender.split(',') : []
          return item
        })
        this.totalPages = Math.ceil(data.total_count / 10)
      } else {
        this.$snackbar({
          message: data.message,
          color: 'error',
          timeout: 5000
        })
        this.campaignData = []
      }
      this.loading = false
    }
  }
}
</script>

<style scoped>
  h4 {
    text-transform: uppercase;
    margin: 0;
  }
  .v-data-table >>> .v-data-table__wrapper > table {
    border-spacing: 0 12px !important;
  }
  .theme--light.v-data-table .v-data-table__wrapper table th {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    border: none !important;
    padding: 16px 10px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-weight: normal;
    font-size: 14px;
    color: #333333;
    border: none !important;
    padding: 16px 10px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.09);
    border-radius: 13px;
  }
  .campaign .v-card p {
    margin: 0 0 0;
    width: 130px;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    color: #333333;
  }
  @media (min-width: 1400px) {
    .campaign .v-card p {
      width: 155px;
    }
  }
  strong {
    font-weight: 500;
  }
  .label {
    font-weight: 400;
  }
  .campaign p strong {
    color: #333333;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .campaign p.desc strong {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .cover {
    /* box-shadow: -2px 3px 7px rgba(0, 0, 0, 0.09) !important; */
    border-radius: 11px;
    width: 160px;
  }
  .v-input >>> .v-input--selection-controls__input {
    margin: 0 !important;
  }
</style>
