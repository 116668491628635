<template>
  <div class="login">
    <v-alert
      outlined
      type="error"
      v-if="alert"
    >{{ alertMsg }}</v-alert>
    <v-form
      v-model="valid"
      lazy-validation
      ref="login"
      v-on:submit.prevent="onSubmit"
    >
      <label class="label">Mobile Number <small></small></label>
      <v-text-field
        v-model="loginData.mobile"
        :rules="loginRules.mobile"
        placeholder="Enter Mobile No."
        solo
      ></v-text-field>

      <label class="label">Password</label>
      <v-text-field
        v-model="loginData.password"
        :rules="loginRules.password"
        type="password"
        placeholder="Enter Password"
        solo
      ></v-text-field>

      <v-btn
        depressed
        dark
        large
        block
        color="primary"
        style="width: 252px;"
        :disabled="loading"
        :loading="loading"
        type="submit"
        @click="doLogin()"
      >Login</v-btn>
    </v-form>
  </div>
</template>

<script>
import { userLogin } from '@/api/user'

export default {
  data () {
    return {
      valid: true,
      loading: false,
      loginData: {},
      user: {},
      alertMsg: '',
      alert: false,
      loginRules: {
        mobile: [
          v => !!v || 'Phone number is required',
          v => /^[+|\d+][0-9]{9}$/.test(v) || 'Invalid Phone number'
        ],
        password: [
          v => !!v || 'Password is required'
        ]
      }
    }
  },
  methods: {
    async doLogin () {
      if (this.$refs.login.validate()) {
        try {
          this.loading = true
          const data = await userLogin(this.loginData)
          if (data.token) {
            this.user = data.userInfo
            document.cookie = `influencer_token=${data.token}; path=/; secure; domain=.soocel.com`
            window.localStorage.setItem('user-token', data.token)
            window.localStorage.setItem('user', JSON.stringify(data.userInfo))
            if (!data.userInfo.status || !data.userInfo.status === 2) {
              this.$router.push('/user/connect')
              // this.$router.push('/user/add-info')
            // } else if (!this.user.name || this.user.name === '') {
            //   this.$router.push('/user/add-info?step=2')
            } else {
              if (this.$route.query.redirect) {
                this.$router.push(this.$route.query.redirect)
              } else {
                this.$router.push('/dashboard')
              }
            }
          } else {
            this.alertMsg = data.message
            this.alert = true
          }
        } catch (error) {
          this.alertMsg = 'Something went wrong'
          this.alert = true
          console.log(error)
        }
      } else {
        console.log('error submit!!')
        return false
      }
      this.loading = false
    }
  }
}
</script>

<style scoped>
</style>
