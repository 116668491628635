import request from './index'

export const getappliedList = (data) => {
  return request({
    method: 'GET',
    url: '/campaign/appliedlist',
    params: data
  })
}

export const getList = (data) => {
  return request({
    method: 'GET',
    url: '/campaign/list',
    params: data
  })
}

export const assignDelete = (data) => {
  return request({
    method: 'DELETE',
    url: '/campaign/assignDelete',
    data: data
  })
}

export const applyCampaign = (data) => {
  return request({
    method: 'POST',
    url: '/campaign/apply',
    data: data
  })
}

export const getInfo = (campaignId) => {
  return request({
    method: 'GET',
    url: `/campaign/${campaignId}`
  })
}

export const applyChannel = (campaignId) => {
  return request({
    method: 'GET',
    url: `/campaign/applychannel/${campaignId}`
  })
}

export const createCampaign = (data) => {
  return request({
    method: 'POST',
    url: '/campaign/create',
    data: data
  })
}

export const updateCampaign = (data) => {
  return request({
    method: 'POST',
    url: '/campaign/update',
    data: data
  })
}

export const deleteCampaign = (id) => {
  return request({
    method: 'DELETE',
    url: `/campaign/${id}`
  })
}

export const campaignPost = (data) => {
  return request({
    method: 'GET',
    url: '/campaign/campaign-post',
    params: data
  })
}

export const addPost = (data) => {
  return request({
    method: 'POST',
    url: '/campaign/addPost',
    data: data
  })
}
export const updatePost = (data) => {
  return request({
    method: 'POST',
    url: '/campaign/updatePost',
    data: data
  })
}
