<template>
  <div class="login">
    <v-row no-gutters>
      <v-col cols="12" md="7" class="login-bg mobile-hidden" ></v-col>
      <v-col cols="12" md="5">
        <div class="text-center">
          <a href="https://www.soocel.com" class="d-flex justify-center align-center">
            <img src="@/assets/images/soocel-logo.png" height="45" class="logo" />
          </a>
        </div>

        <h2 class="text-center">Influencer Registration</h2>

        <v-form
          class="login-form"
          v-model="valid"
          lazy-validation
          ref="addInfo"
          v-on:submit.prevent="onSubmit"
        >
          <v-alert
            outlined
            type="error"
            v-if="alert"
          >{{ alertMsg }}</v-alert>

          <div>
            <label class="label">Name</label>
            <v-text-field
              v-model="user.name"
              :rules="addInfoRules.name"
              placeholder="Enter name"
              solo
            ></v-text-field>

            <label class="label">Email</label>
            <v-text-field
              v-model="user.email"
              :rules="addInfoRules.email"
              placeholder="Enter email address"
              solo
            ></v-text-field>

            <label class="label">Password</label>
            <v-text-field
              v-model="user.password"
              :rules="addInfoRules.password"
              type="password"
              placeholder="Set a password"
              solo
            ></v-text-field>

            <label class="label">Confirm Password</label>
            <v-text-field
              v-model="user.confirmPassword"
              :rules="addInfoRules.confirmPassword"
              type="password"
              placeholder="Confirm your password"
              solo
            ></v-text-field>
          </div>

          <!-- <div v-if="step === 2">

            <label class="label">Name</label>
            <v-text-field
              v-model="user.name"
              :rules="addInfoRules.name"
              placeholder="Enter your name"
              solo
            ></v-text-field>

            <label class="label">
              Channel Link

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" small v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
                </template>
                <span>https://www.youtube.com/channel/channelid &nbsp; &nbsp;OR &nbsp;&nbsp; https://www.instagram.com/username</span>
              </v-tooltip>
            </label>
            <div
              class="d-flex align-start justify-space-between"
              v-for="(url, index) in channelUrlList"
              :key="index"
            >
              <v-text-field
                v-model="channelUrlList[index].url"
                :rules="addInfoRules.channelUrl"
                placeholder="Enter your channel link"
                solo
              ></v-text-field>
              <v-icon
                size="28"
                color="primary"
                class="ml-1 mt-4"
                v-if="index >= 1"
                @click="channelUrlList.splice(index, 1)"
              >mdi-delete-outline</v-icon>
            </div>
            <v-btn
              color="primary"
              class="mb-5"
              @click="channelUrlList.push({ url: '' })"
              small
              text
            >
              <v-icon
                small
                left
              >mdi-plus-circle</v-icon>
              Add Another Channel
            </v-btn>
          </div> -->

          <v-btn
            depressed
            dark
            large
            block
            color="primary"
            style="width: 252px;"
            :disabled="loading"
            :loading="loading"
            @click="updateInfo()"
          >Update</v-btn>

        </v-form>

      </v-col>
    </v-row>
  </div>
</template>

<script>
import { updateProfile, getUserInfo } from '@/api/user'

export default {
  data () {
    return {
      valid: false,
      loading: false,
      step: Number(this.$route.query.step) || 1,
      channelUrlList: [{ url: '' }],
      user: JSON.parse(window.localStorage.getItem('user')),
      alertMsg: '',
      alert: false,
      addInfoRules: {
        channelUrl: [
          v => !!v || 'Channel link required',
          v => (/^.*www.youtube.com\/(channel)\/.*/.test(v) || /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/.test(v)) || 'Please use this format (https://www.youtube.com/channel/channelid) or (https://www.instagram.com/username)'
        ],
        name: [
          v => !!v || 'Name is required',
          v => /^[a-zA-Z\s]+$/.test(v) || 'Invalid name'
        ],
        companyName: [
          v => !!v || 'Company name required'
        ],
        email: [
          v => !!v || 'E-mail is required',
          v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Invalid E-mail'
        ],
        password: [
          v => !!v || 'Password is required'
        ],
        confirmPassword: [
          v => !!v || 'Password is required',
          v => v === this.user.password || 'Password not match'
        ]
      }
    }
  },
  methods: {
    async updateInfo () {
      // const globalRegex = RegExp(/^.*www.youtube.com\/channel.*/, 'g')
      // globalRegex.test(this.channelUrlList[0].channelUrl)
      if (this.$refs.addInfo.validate()) {
        try {
          this.loading = true
          // this.user.channelUrlList = this.channelUrlList.map(channel => channel.url)
          const data = await updateProfile(this.user)
          if (data.success) {
            const response = await getUserInfo()
            const localData = {
              id: response.info.uuid,
              mobile: response.info.mobile,
              email: response.info.email,
              name: response.info.name
            }
            window.localStorage.setItem('user', JSON.stringify(localData))
            this.user = response.info
            // push user to dashboard
            this.$router.push('/dashboard')
          } else {
            this.alertMsg = data.message
            this.alert = true
          }
          this.loading = false
        } catch (error) {
          this.loading = false
          this.alertMsg = 'Something went wrong'
          this.alert = true
          console.log(error)
        }
      } else {
        console.log('error submit!!')
        return false
      }
    }
  }
}
</script>

<style scoped>
  .logo {
    margin: 40px 0 20px 0;
  }
  .label {
    line-height: 10px;
  }
  .label small {
    font-weight: 400;
    font-size: 12px;
  }
</style>
