<template>
  <div class="profile">

    <v-img
      src="@/assets/images/profile-cover.png"
      :aspect-ratio="16/3.5"
      class="page-cover"
    />
    <v-progress-linear
      class="my-4"
      indeterminate
      color="primary"
      v-if="loading"
    ></v-progress-linear>
    <v-form
      v-else
      v-model="valid"
      v-on:submit.prevent="onSubmit"
      lazy-validation
      ref="influencerinfo"
    >
      <v-row
        class="profile-form"
        no-gutters
      >
        <v-col
          cols="3"
          class="profile-cover d-flex justify-center align-center"
        >

          <v-form
            class="picture d-flex align-center justify-center"
            ref="form"
            enctype="multipart/form-data"
          >
            <input
              type="file"
              :name="uploadFieldName"
              :disabled="isSaving"
              @change="filesChange($event.target.name, $event.target.files);
              fileCount = $event.target.files.length"
              accept="image/*"
              class="input-file"
            />
            <v-img
              v-if="(user.avatar && user.avatar !== '') && !isSaving"
              :src="user.avatar"
              :aspect-ratio="16/16"
              width="203"
            />
            <v-icon
              v-else-if="!isSaving"
              large
              color="primary"
            >mdi-image-plus-outline</v-icon>
            <v-progress-circular
              indeterminate
              color="primary"
              v-if="isSaving"
            ></v-progress-circular>
            <v-icon
              v-if="!isSaving && user.avatar"
              color="primary"
              class="img-edit-icon"
            >mdi-image-edit-outline</v-icon>
          </v-form>

        </v-col>
        <v-col cols="9 pt-3">
          <label class="label">Influencer Name</label>
          <v-text-field
            :rules="rules.name"
            v-model="user.name"
            placeholder="Influencer Name"
            solo
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <label class="label">Contact Number</label>
          <v-text-field
            :rules="rules.mobile"
            v-model="user.mobile"
            placeholder="Mobile Number"
            solo
            disabled
          ></v-text-field>
          <label class="label">Password</label>
          <v-text-field
            v-model="user.password"
            placeholder="Enter password"
            type="password"
            solo
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <label class="label">Contact Email</label>
          <v-text-field
            :rules="rules.email"
            v-model="user.email"
            placeholder="Contact person email"
            solo
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <h3>Authorize your channels
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  v-bind="attrs"
                  color="secondary"
                  v-on="on"
                >mdi-help-circle-outline</v-icon>
              </template>
              <div>
                <v-icon
                  small
                  color="white"
                  class="mr-2"
                >mdi-youtube</v-icon><span>For YouTube</span><br>
                <ul>
                  <li>User should have YouTube channel.</li>
                </ul>
                <span>
                  <v-icon
                    small
                    color="white"
                    class="mr-2"
                  >mdi-instagram
                  </v-icon>For Instagram
                </span><br>
                <ul>
                  <li>User should have Instagram Business account and Facebook page with Instagram linked to the FB page.</li>
                </ul>
              </div>
            </v-tooltip>
          </h3>
        </v-col>

        <v-col cols="12" class="d-flex connect-channel">
          <div class="connect-box d-flex align-center justify-center mr-8">
            <v-icon
              class="mr-2"
              color="#ff0101"
              large
            >mdi-youtube</v-icon>
            <p>YouTube</p>
            <v-btn
              depressed
              outlined
              small
              color="secondary"
              @click="goConcent()"
              v-if="!this.ytConnected"
            >Connect</v-btn>
            <v-btn
              depressed
              outlined
              small
              color="success"
              @click="goConcent()"
              v-else
            >
              <v-icon
                small
                left
              >mdi-check-circle</v-icon>
              Connected
            </v-btn>
          </div>

          <div class="connect-box d-flex align-center justify-center mr-3">
            <img
              class="mr-2"
              src="@/assets/images/icon-instagram.svg"
              width="26"
            />
            <p>Instagram</p>

            <!-- https://www.facebook.com/v14.0/dialog/oauth?response_type=token&client_id=618897076298057&redirect_uri=${instaRedirectUrl}&version=v14.0&auth_type=rerequest&scope=pages_show_list,instagram_basic,instagram_manage_insights,public_profile -->
            <v-btn
              v-if="!this.instaConnected"
              depressed
              outlined
              small
              color="secondary"
              :href="`https://www.facebook.com/v14.0/dialog/oauth?response_type=token&client_id=618897076298057&redirect_uri=${instaRedirectUrl}&version=v14.0&auth_type=rerequest&scope=pages_show_list,instagram_basic,instagram_manage_insights,public_profile,email`"
            >Connect</v-btn>
            <v-btn
              v-else
              depressed
              outlined
              small
              color="success"
              :href="`https://www.facebook.com/v14.0/dialog/oauth?response_type=token&client_id=618897076298057&redirect_uri=${instaRedirectUrl}&version=v14.0&auth_type=rerequest&scope=pages_show_list,instagram_basic,instagram_manage_insights,public_profile,email`"
            >
              <v-icon small left v-if="this.instaConnected">mdi-check-circle</v-icon>
              {{ this.instaConnected ? 'Connected' : 'Connect'}}
            </v-btn>
          </div>
        </v-col>

        <v-col cols="12">
          <h3 class="d-flex align-center justify-space-between">
            <div>Channel Links</div>
            <v-btn
              color="primary"
              @click="channelUrlList.push({ url: '' })"
              small
              text
            >
              <v-icon
                small
                left
              >mdi-plus-circle</v-icon>
              Add Channel
            </v-btn>
          </h3>
        </v-col>

        <v-col
          cols="6"
          v-for="(url, index) in channelUrlList"
          :key="`${url}_${index}`"
        >
          <div class="d-flex align-center justify-space-between">
            <label class="label">Channel Link {{ index + 1 }}
              <v-tooltip
                bottom
                v-if="channelUrlList[index].verify===1"
                color="success"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="success"
                    medium
                    class="mr-1"
                    v-bind="attrs"
                    v-on="on"
                  >mdi-check-decagram</v-icon>
                </template>
                <span>Authorized Channel</span>
              </v-tooltip>
              <v-tooltip
                bottom
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >mdi-help-circle-outline</v-icon>
                </template>
                <span>https://www.youtube.com/channel/channelid &nbsp; &nbsp;OR &nbsp;&nbsp; https://www.instagram.com/username</span>
              </v-tooltip>
            </label>
            <v-icon
              size="28"
              color="primary"
              v-if="index >= 1 && !channelUrlList[index].disabled"
              @click="channelUrlList.splice(index, 1)"
            >mdi-delete-outline</v-icon>
          </div>
          <div class="d-flex align-start justify-space-between">
            <v-text-field
              v-model="channelUrlList[index].url"
              :rules="rules.channelUrl"
              :disabled="channelUrlList[index].disabled"
              placeholder="Enter channel link"
              solo
            ></v-text-field>
          </div>
        </v-col>

        <v-col cols="12">
          <h3>Financial Information</h3>
        </v-col>

        <v-col cols="6">
          <label class="label">Account Holder Name</label>
          <v-text-field
            :rules="rules.accountName"
            v-model="user.accountName"
            placeholder="Your Bank Account Name"
            solo
          ></v-text-field>
          <label class="label">IFSC</label>
          <v-text-field
            :rules="rules.ifsc"
            v-model="user.ifsc"
            placeholder="Branch IFSC Code"
            solo
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <label class="label">Account Number</label>
          <v-text-field
            :rules="rules.accountNumber"
            v-model="user.accountNumber"
            placeholder="Your Bank Account Number"
            solo
          ></v-text-field>
          <label class="label">PAN</label>
          <v-text-field
            :rules="rules.pan"
            v-model="user.pan"
            placeholder="PAN"
            solo
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          class="text-right mb-8"
        >
          <v-btn
            depressed
            dark
            :loading="loadingbtn"
            large
            color="primary"
            style="width: 252px;"
            @click="updateProfile()"
          >Update Profile</v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-dialog v-model="instaDialogue" width="500">
      <v-card>
        <v-card-text class="pa-8">
          <v-form v-model="instaInfoValid" v-on:submit.prevent="onSubmit" lazy-validation ref="instaInfo">
            <label class="label">Instagram Username</label>
            <v-text-field
              v-model="instaProfile.username"
              placeholder="eg. soocel_digital"
              :rules="rules.username"
              solo
            ></v-text-field>
            <v-btn v-if="instaProfile.graphql" class="mt-1" color="primary" :loading="instaLoading" @click="fetchInstaProfile()">Submit</v-btn>
            <v-btn v-else class="mt-1" color="primary" :loading="instaLoading" @click="fetchInstaProfile()">Submit</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import axios from 'axios'
import { getUserInfo, updateProfile, updateInsta } from '@/api/user'

export default {
  data () {
    return {
      avatar: null,
      channelname: null,
      publishedAt: null,
      subscribers: null,
      videoCount: null,
      viewCount: null,
      authCode: '',
      loading: false,
      loadingbtn: false,
      ytConnected: false,
      instaConnected: false,
      instaDialogue: false,
      instaLoading: false,
      instaProfile: { username: '' },
      instaRedirectUrl: `${window.location.origin}/auth/instagram`,
      authConsentUrl: '',
      code: '',
      valid: true,
      instaInfoValid: true,
      isSaving: false,
      uploadFieldName: 'avatar',
      channelUrlList: [{ url: '' }],
      user: { avatar: null },
      rules: {
        channelUrl: [
          v => !!v || 'Channel link required',
          v => (/^.*www.youtube.com\/(channel)\/.*/.test(v) || /^.*www.instagram.com\/.*/.test(v)) || 'Please use this format (https://www.youtube.com/channel/channelid) or (https://www.instagram.com/username)'
        ],
        email: [
          v => !!v || 'E-mail is required',
          v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Invalid e-mail'
        ],
        mobile: [
          v => !!v || 'Phone number is required',
          v => /^[+|\d+][0-9]{9}$/.test(v) || 'Invalid Phone number'
        ],
        ifsc: [
          v => !!v || 'Ifsc number is required',
          v => /^[a-zA-Z0-9\s]{11}$/.test(v) || 'Ifsc number should be 11 digit'
        ],
        pan: [
          v => !!v || 'Pan number is required',
          v => /^[a-zA-Z0-9\s]{10}$/.test(v) || 'Pan number should be 10 digit'
        ],
        name: [
          v => !!v || 'Influencer name is required',
          v => /^[a-zA-Z\s]+$/.test(v) || 'Invalid name'
        ],
        username: [
          v => !!v || 'Instagram username is required'
        ],
        accountName: [
          v => !!v || 'Account holder name is required',
          v => /^[a-zA-Z\s]+$/.test(v) || 'Invalid account holder name'
          // v => /^\w+$/.test(v) || 'Invalid account holder name'
        ],
        accountNumber: [
          v => !!v || 'Account number is required',
          v => /^[+|\d+][0-9]{9,20}$/.test(v) || 'Invalid account number'
        ]
      }
    }
  },
  mounted: async function () {
    if (this.$route.query.code) {
      this.concentauthCode()
    } else {
      await this.getUserData()
      this.getAuthConsentUrl()
    }
    // this.getUserData()
  },
  methods: {
    async getUserData () {
      this.loading = true
      const data = await getUserInfo()
      this.loading = false
      this.user = {
        id: data.info.uuid,
        mobile: data.info.mobile,
        email: data.info.email,
        ifsc: data.info.ifsc ? data.info.ifsc.toUpperCase() : null,
        pan: data.info.pan ? data.info.pan.toUpperCase() : null,
        name: data.info.name,
        accountNumber: data.info.account_number,
        accountName: data.info.account_name,
        companyName: data.info.company_name,
        avatar: data.info.avatar,
        ytChannel: data.ytChannel,
        instaChannel: data.instaChannel,
        status: data.info.status
      }
      window.localStorage.setItem('status', data.info.status)
      window.localStorage.setItem('user', JSON.stringify(this.user))

      if (data.channelLinks && data.channelLinks.length) {
        this.channelUrlList = []
        for (let index = 0; index < data.channelLinks.length; index++) {
          const channel = data.channelLinks[index]
          const url = channel.media_type === 0 ? `https://www.youtube.com/channel/${channel.media_id}` : `https://www.instagram.com/${channel.media_id}`
          this.channelUrlList.push({ url, verify: channel.verify, disabled: true })
          if (channel.media_type === 0 && channel.verify === 1) {
            this.ytConnected = true
          }
          if (channel.media_type === 1 && channel.verify === 1) {
            this.instaConnected = true
          }
        }
      } else {
        this.channelUrlList = [{ url: '' }]
      }
    },
    goConcent () {
      window.location.href = this.authConsentUrl
    },
    async fetchInstaProfile () {
      if (this.$refs.instaInfo.validate()) {
        // this.instaLoading = true
        this.instaProfile.edge_followed_by = 0
        this.instaProfile.edge_follow = 0
        this.instaProfile.edge_owner_to_timeline_media = 0
        this.updateInstaInfo()
        // const response = await axios.get(`https://www.instagram.com/${this.instaProfile.username}/?__a=1&__d=dis`)
        // if (response.graphql) {
        //   this.instaProfile = response.graphql.user
        // } else {
        //   this.instaProfile.edge_followed_by = 0
        //   this.instaProfile.edge_follow = 0
        //   this.instaProfile.edge_owner_to_timeline_media = 0
        //   this.updateInstaInfo()
        // }
        // this.instaLoading = false
      }
    },
    async updateInstaInfo () {
      this.instaLoading = true
      const response = await updateInsta(this.instaProfile)
      if (response.success) {
        this.$snackbar({
          message: response.message
        })
        this.getUserData()
      } else {
        this.$snackbar({
          message: response.message,
          color: 'error',
          timeout: 5000
        })
      }
      this.instaLoading = false
      this.instaDialogue = false
    },
    async updateProfile () {
      if (this.$refs.influencerinfo.validate()) {
        this.loadingbtn = true
        const submitData = {
          name: this.user.name,
          avatar: this.user.avatar,
          password: this.user.password,
          accountName: this.user.accountName,
          ifsc: this.user.ifsc.toUpperCase(),
          email: this.user.email,
          accountNumber: this.user.accountNumber,
          pan: this.user.pan.toUpperCase(),
          channelUrlList: this.channelUrlList.map(channel => ({ link: channel.url, verify: channel.verify }))
        }

        const data = await updateProfile(submitData)
        this.loadingbtn = false
        if (data.success) {
          this.$snackbar({
            message: data.message
          })
          // this.getUserData()
        } else {
          this.$snackbar({
            message: data.message,
            color: 'error',
            timeout: 5000
          })
        }
      } else {
        console.log('error submit!!')
        return false
      }
    },
    async getAuthConsentUrl () {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + window.localStorage.getItem('user-token')
          }
        }
        const response = await axios.post('/api/youtube/authConsentUrl', { page: 1 }, config)
        if (response.data.success) {
          this.authConsentUrl = response.data.channelauthurl
        } else {
          this.$snackbar({
            color: 'error',
            message: response.data.message
          })
        }
      } catch (error) {
        this.$snackbar({
          color: 'error',
          message: 'Something went wrong'
        })
      }
    },
    concentauthCode () {
      this.authCode = this.$route.fullPath
      var reg = /code/
      var regResult = reg.test(this.authCode)
      if (regResult) {
        this.code = this.$route.query.code
        this.code = decodeURIComponent(this.code)
      }
      this.loginLastStep()
    },
    async loginLastStep () {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + window.localStorage.getItem('user-token')
          }
        }
        this.loading = true
        const response = await axios.post('/api/youtube/youtubeLogin', { code: this.code, page: 1, name: this.user.name }, config)
        if (response.data.success) {
          this.ytConnected = true
          this.$snackbar({
            message: response.data.message
          })
        } else {
          this.$snackbar({
            color: 'error',
            message: response.data.message
          })
          this.loading = false
        }
      } catch (error) {
        this.$snackbar({
          color: 'error',
          message: 'Something went wrong'
        })
        this.loading = false
      }
      await this.getUserData()
      this.getAuthConsentUrl()
      this.$router.push('/profile/info')
    },
    // image upload
    async save (formData) {
      // upload data to the server
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + window.localStorage.getItem('user-token')
        }
      }
      const response = await axios.post('/api/operations/image-upload', formData, config)
      if (response.data.success) {
        this.isSaving = false
        this.user.avatar = `${response.data.url}?v=${new Date().valueOf()}`
        this.updateProfile()
      }
      this.isSaving = false
    },

    filesChange (fieldName, fileList) {
      this.isSaving = true
      // handle file changes
      const formData = new FormData()
      if (!fileList.length) return
      if (fileList[0].size > 1048576) {
        this.isSaving = false
        this.$snackbar({
          message: 'Size is too large, Please upload image less than 1MB!',
          color: 'error',
          timeout: 5000
        })
        return
      }
      // append the files to FormData
      Array
        .from(Array(fileList.length).keys())
        .map(x => {
          formData.append(fieldName, fileList[x], fileList[x].name)
        })
      // save it
      this.save(formData)
    }
  }
}
</script>

<style scoped>
  h4 {
    text-transform: uppercase;
    margin: 0 0 20px;
  }
  h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #6b6b6b;
    background: rgba(217, 217, 217, 0.18);
    border-radius: 11px;
    padding: 15px 32px;
    margin: 0 0 10px;
  }
  .profile-cover {
    margin: -102px auto 0;
  }
  .profile-cover .v-image {
    border-radius: 11px;
    box-shadow: -2px 3px 7px rgba(0, 0, 0, 0.16);
    margin: 0 auto;
  }
  .v-image.page-cover {
    border-radius: 8px;
    /* box-shadow: -2px 3px 7px rgba(0, 0, 0, 0.16); */
    margin: 0 auto;
  }

  /* Image Upload */
  .picture {
    text-align: center;
    /* margin: 30px 0 0; */
    border-radius: 11px;
    position: relative;
    cursor: pointer;
    background: #f5f5f5;
    width: 203px;
    height: 203px;
    display: inline-block;
  }
  .picture .img-edit-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    z-index: 20;
    border-radius: 11px;
  }
  .connect-box {
    border: 1px solid #eee;
    padding: 5px 10px;
    border-radius: 3px;
  }
  .connect-box p {
    margin: 0 50px 0 0;
  }
</style>
